import React from 'react';
import './App.css';
// import Encrypt from "./encrypt";
import Decrypt from "./decrypt";

function App() {
  return (
    <div className="App">
      {/*<Encrypt />*/}
      <Decrypt />
    </div>
  );
}

export default App;
