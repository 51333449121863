import React from "react";
import * as CryptoJS from 'crypto-js';

function debounce(func, wait, immediate) {
    var timeout;

    return function executedFunction() {
        var context = this;
        var args = arguments;

        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        var callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
}

const Decrypt = () => {
    const [key, setKey] = React.useState();
    const [result, setResult] = React.useState();

    const sendRequest = debounce((input) => {
        fetch('https://us-central1-twophase-3f2eb.cloudfunctions.net/decrypt', {
            method: 'post',
            body: input
        }).then((response) => response.text())
            .then((text) => {
                setResult(text);
            })
    }, 2000);

    const handleKeyChange = (event) => {
        event.persist();
        sendRequest(event.target.value);
    };

    return (
        <div>
            <div>
                <input type="text" value={key} onChange={handleKeyChange}/>
            </div>
            <div>
                {result}
            </div>
        </div>
    );
};

export default Decrypt;